import { useState, useEffect, useRef } from "react";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom/cjs/react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css'
import Window from "./Window"; import { FaMinus, FaRegWindowMaximize } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";



const Hero = ({ handleOpenWeb }) => {

    const [audioStarted, setAudioStarted] = useState(false);

    const startAudio = () => {
        const audio = document.getElementById('myAudio');
        audio.play();
        setAudioStarted(true);
    };

    AOS.init({ once: true });
    const dragConstraints = {
        left: 0,
        right: window.innerWidth - 100,
        top: 0,
        bottom: window.innerHeight - 100,
    };
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768); // Adjust the threshold as needed
        };

        handleResize(); // Set initial state
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const buttonStyle = "bg-[#fff] flex flex-row items-center h-[100vh] w-full gap-2 text-[#000] py-1 px-10 md:px-14 text-12px md:text-[14px] transform duration-300 hover:bg-[#E8A90E] rounded-full font-bold"

    return (<div className="h-[100%] py-10 flex flex-col items-center justify-center gap-5 w-[90%] md:w-[50%] text-center mx-auto text-[#f2f2f2]">

        <div className="flex flex-col-reverse md:flex-row  md:gap-0 gap-5">
            {/* NAV */}
            <motion.div className="z-[5] md:self-start self-center   md:min-w-[400px] min-w-[300px] min-h-[300px] border-[#F4D083] border-solid border-l-[2px] border-b-[#F91F51]  border-[2px]  "
                {...(isDesktop && { drag: true })}
                dragConstraints={dragConstraints}
            >
                <div className="border-[#F91F51] border-b-[#F4D083] w-full h-[100%] border-solid border-[5px] ">
                    <div className="w-full flex flex-row  px-1 bg-gradient-to-b from-[#F91F51] items-center justify-between  to-[#F4D083]">
                        <h1 className="font-black text-xl uppercase"> links</h1>
                        <div className="flex items-center flex-row gap-1">
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] pt-[0.125rem] px-2 self-end border-b-[#000] border-[3px]">
                                <FaMinus className="" />
                            </div>
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <FaRegWindowMaximize className="" />
                            </div>
                            <div onClick={handleOpenWeb} className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <AiOutlineClose className="text-[#F91F51] font-bold" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col bg-[#ffffffa8] h-[92%]">
                        <div className="w-full  flex flex-col items-start justify-start gap-0 bg-[#fff] text-[#F91F51] py-1 border-t-[#F91F51] border-[3px] border-b-[#F91F51] pl-5">
                            <a href="https://x.com/Solvalentines" target="_blank" rel="noopener noreferrer" className="text-sm font-mono flex flex-row items-center">❤️<span className="underline">twitter/x</span></a>
                            <a href="https://t.me/solvalentinescoin" target="_blank" rel="noopener noreferrer" className="text-sm font-mono flex flex-row items-center">❤️<span className="underline">telegram</span></a>
                            <a href="#" className="text-sm font-mono flex flex-row items-center" target="_blank" rel="noopener noreferrer">❤️<span className="underline">buy $love</span></a>
                            <a href="#" className="text-sm font-mono flex flex-row items-center" target="_blank" rel="noopener noreferrer">❤️<span className="underline">dexscreener</span></a>
                        </div>
                        <div className="w-full">
                            <img src="../img/group.png" alt="group" />
                        </div>
                    </div>
                </div>
            </motion.div>
            {/* ABOUT */}
            <motion.div className="md:min-w-[400px] z-[6] md:self-start self-center   max-w-full text-[#000] sm:max-w-[70%] min-h-[250px] border-[#F4D083] border-solid border-l-[2px] border-b-[#F91F51]  border-[2px]  "
                {...(isDesktop && { drag: true })}
                dragConstraints={dragConstraints}
            >
                <div className="border-[#F91F51] border-b-[#F4D083] w-full min-h-[250px] border-solid border-[5px] bg-[#ffffffbd] ">
                    <div className="w-full flex flex-row  px-1 bg-gradient-to-b from-[#F91F51] items-center justify-between  to-[#F4D083]">
                        <h1 className="font-black text-xl uppercase text-white"> About </h1>
                        <div className="flex items-center flex-row gap-1">
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] pt-[0.125rem] px-2 self-end border-b-[#000] border-[3px]">
                                <FaMinus className="" />
                            </div>
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <FaRegWindowMaximize className="" />
                            </div>
                            <div onClick={handleOpenWeb} className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <AiOutlineClose className="text-[#F91F51] font-bold" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col items-start justify-center relative  ">
                        <div className="w-full my-2 border-t-[#F91F51] border-b-[#F91F51] border-[3px] bg-[#fff]">
                            <h1 className="uppercase text-left text-[#000] pl-5 font-bold text-lg "><span className="text-md">❤️</span>Hello!!!</h1>
                        </div>
                        <div className="text-sm leading-tight p-2 text-left w-[50%]">
                            <p>Embracing love, expressing love, and spreading love—this is who we are, the essence of Valentine's Coin.

                                <br />

                                <span>CA: 0000000000000000000000000000</span>
                            </p>
                        </div>
                        <div className="absolute right-0 z-10 top-0 border-[3px] border-[#F91F51]"><img src="../img/about.png" alt="about tinu" className="w-[150px]" /></div>
                    </div>
                </div>
            </motion.div>
        </div>

        <div className="flex flex-col gap-5 md:gap-0 md:flex-row-reverse">
            {/* TINU RUN */}
            <motion.div className="z-[7] md:self-start self-center md:min-w-[400px] max-w-full  sm:max-w-[70%] min-h-[150px] border-[#F4D083] border-solid border-l-[2px] border-b-[#F91F51]  border-[2px]  "
                {...(isDesktop && { drag: true })}
                dragConstraints={dragConstraints}
            >
                <div className="border-[#F91F51] border-b-[#F4D083] w-full min-h-[150px] border-solid border-[5px] bg-[#ffffffbd] ">
                    <div className="w-full flex flex-row  px-1 bg-gradient-to-b from-[#F91F51] items-center justify-between  to-[#F4D083]">
                        <h1 className="font-black text-xl uppercase">$ROCKET ON SOL</h1>
                        <div className="flex items-center flex-row gap-1">
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] pt-[0.125rem] px-2 self-end border-b-[#000] border-[3px]">
                                <FaMinus className="" />
                            </div>
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <FaRegWindowMaximize className="" />
                            </div>
                            <div onClick={handleOpenWeb} className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <AiOutlineClose className="text-[#F91F51] font-bold" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col items-start justify-center relative  ">
                        <img src="../img/moon.gif" alt="moon" className="w-full h-full" />
                    </div>
                </div>
            </motion.div>
            {/* :) */}
            <motion.div className="md:self-start z-[8] self-center md:min-w-[400px] max-w-full  sm:max-w-[70%] min-h-[150px] border-[#F4D083] border-solid border-l-[2px] border-b-[#F91F51]  border-[2px]  "
                {...(isDesktop && { drag: true })}
                dragConstraints={dragConstraints}
            >
                <div className="border-[#F91F51] border-b-[#F4D083] w-full min-h-[150px] border-solid border-[5px] bg-[#ffffffbd] ">
                    <div className="w-full flex flex-row  px-1 bg-gradient-to-b from-[#F91F51] items-center justify-between  to-[#F4D083]">
                        <h1 className="font-black text-xl uppercase">{`:) Your Love Music`}</h1>
                        <div className="flex items-center flex-row gap-1">
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] pt-[0.125rem] px-2 self-end border-b-[#000] border-[3px]">
                                <FaMinus className="" />
                            </div>
                            <div className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <FaRegWindowMaximize className="" />
                            </div>
                            <div onClick={handleOpenWeb} className="bg-[#fff] cursor-pointer hover:border-b-[#F4D083] text-[#F91F51] hover:text-[#F4D083] border-t-[#F4D083] hover:border-r-[#F4D083] border-l-[#F4D083] hover:border-l-[#000] hover:border-t-[#000] border-r-[#000] py-[1px] px-2 self-end border-b-[#000] border-[3px]">
                                <AiOutlineClose className="text-[#F91F51] font-bold" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col items-center justify-center px-1  py-3 ">
                        <div>
                            {!audioStarted && (
                                <button onClick={startAudio}>Start Audio</button>
                            )}
                            <audio controls autoPlay={audioStarted} id="myAudio" src="../audio/perfect.mp3" type="audio/mpeg"></audio>
                        </div>
                        <div>
                            <h1 className="text-black">info@loveonsol.xyz</h1>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>




    </div>);
}

export default Hero;
import { Hero, Buy, WhyBob, RoadMap } from "../components";
import { useState } from "react";
import { motion } from 'framer-motion'
import { slideIn } from "../utils/motion";
import ScrollToTopButton from "../components/ScrollToTopBar";
import About from "../components/About";
import Tokenomics from "../components/Tokenomics";
import Uniswap from "../components/Uniswap";

const Home = ({ pryColor, secColor, interColor, divHeight, divWidth }) => {

    const [openWeb, setOpenWeb] = useState(false);
    const handleOpenWeb = () => {
        setOpenWeb(prevState => !prevState);
    }

    function scrollToDiv() {
        const myDiv = document.getElementById('#Tokenomics');
        myDiv.scrollIntoView({ behavior: 'smooth' });
    }

    const [viewToken, setViewToken] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const handleMenu = () => {
        setShowMenu(prevState => !prevState);
    }

    return (<div className="w-full h-full  relative flex flex-col  mx-auto items-center justify-center ">
        <div  >
            <Hero
                pryColor={pryColor}
                secColor={secColor}
                interColor={interColor}
                handleOpenWeb={handleOpenWeb}

            />
        </div>

        {!openWeb && <div className="w-full z-20 top-0  left-0 h-[100%] bg-[#000a] flex  absolute items-center justify-center">
            <div className="z-20 bg-[#fff] p-1 cursor-pointer" onClick={handleOpenWeb}>
                <h1 className="font-bold text-lg">Enter Site</h1>
            </div>
        </div>}







    </div>);
}

export default Home;